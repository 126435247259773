<template>
  <section class="section">
    <div class="container">
      <div class="box has-background-eurotext-light has-text-white-bis">
        <h2
          class="title is-6 has-text-white-bis has-text-weight-bold has-text-centered"
        >
          {{ $t("password_change.change_pec_password_title") }}
        </h2>
        <PasswordChangeForm
          :askOldPassword="false"
          @change-password="changeOfficePECPassword"
          label-class="has-text-weight-normal has-text-white-bis"
        >
          <ValidationProvider
            rules="required"
            vid="officeSelect"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="$t('password_change.label.select_office')"
              :message="errors"
              :type="{ 'is-danger': errors[0], '': valid }"
              custom-class="has-text-white-bis"
            >
              <b-select
                :placeholder="$t('password_change.placeholder.office_select')"
                icon="office-building"
                v-model="selectedOfficeId"
                :loading="isLoadingOffices"
              >
                <option></option>
                <option
                  v-for="office in offices"
                  :value="office.id"
                  :key="office.id"
                >
                  {{ office.label }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </PasswordChangeForm>

        <b-loading
          :is-full-page="false"
          v-model="isLoadingPassword"
        ></b-loading>
      </div>
    </div>
  </section>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import PasswordChangeForm from "@/components/PasswordChangeForm";
import { userService } from "@/services";
export default {
  components: {
    PasswordChangeForm,
    ValidationProvider,
  },
  data() {
    return {
      isLoadingPassword: false,
      isLoadingOffices: false,
      offices: [],
      selectedOfficeId: null,
    };
  },
  computed: {
    companyName: function () {
      return this.$route.params.company;
    },
  },
  methods: {
    changeOfficePECPassword(oldPassword, newPassword) {
      this.isLoadingPassword = true;
      userService
        .updateOfficePECPassword(
          this.companyName,
          this.selectedOfficeId,
          newPassword
        )
        .then((data) => {
          if (data) {
            this.$buefy.toast.open({
              message: this.$t("messages.password_updated_success"),
              type: "is-success",
            });
          } else {
            this.$buefy.toast.open({
              message: this.$t("error.wrong_password"),
              type: "is-danger",
            });
          }
        })
        .finally(() => (this.isLoadingPassword = false));
    },
    fetchOffices() {
      this.isLoadingOffices = true;
      userService
        .getOfficesPEC(this.companyName)
        .then((data) => {
          if (data.length) this.offices = data;
        })
        .finally(() => (this.isLoadingOffices = false));
    },
  },
  created() {
    this.fetchOffices();
  },
};
</script>

<style></style>
